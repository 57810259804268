import React, { useState } from "react";
import "./ContactUs.css"; // Assuming the CSS is in this file
import markerIcon from '../../Resource/Innodhee-loco.png'
import { useRef } from "react";
import { useAlert } from '../../ProHelpers/AlertContext'; // Import the useAlert hook
import AxiosInstance from '../../Components/RestApi/AxiosInstance';
import LoadingPopup from '../../ProHelpers/LoadingPopup';

const ContactUs = () => {
    const { showAlert } = useAlert();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("Please wait, loading...");
    const form = useRef()
    const initialFormState = {
        name: '',
        email: '',
        mailmessage: ''
    };

    const [formData, setFormData] = useState(initialFormState);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const sendEmail = async (e) => {
        e.preventDefault(); // Prevent the form from refreshing the page

        // Extracting form data
        const formData = new FormData(form.current);
        const data = {
            name: formData.get('name'),
            email: formData.get('email'),
            mailmessage: formData.get('mailmessage')
        };
        console.log("Form data for contact: ", data)
        try {
            setLoading(true);  // Start loading
            // Make the API request using Axios
            //console.log("Form data for contact: ", data.name)
            const response = await AxiosInstance.post('/SendMail', data);
            setLoading(false);  // Start loading
            // Handle success - Display success response in an alert
            //console.log("Form data for contact: ", response)

            setFormData(initialFormState);
            if (response.status === 200) {
                setTimeout(() => showAlert(response.data.message, 'success'), 0);
            } else {
                setTimeout(() => showAlert(response.data.message, 'warning'), 0);
            }
        } catch (error) {
            // Handle error - Display error response in an alert

            setTimeout(() => showAlert('oops!!! Something Went Wrong.', 'warning'), 0);
        }
    };
    return (
        <section className="contact-section">
            {loading && <LoadingPopup message={message} />}
            {/* Google Map Container */}
            <div className="map-container">
                <iframe
                    title="Google Map"
                    src="https://maps.google.com/maps?q=12.9243866,77.6508897&hl=en&z=17&output=embed&center=12.9243866,77.647"
                    allowFullScreen=""
                    loading="lazy"
                    className="google-map"
                ></iframe>
            </div>

            {/* Contact Form Container */}
            <div className="contact-form-container">
                {/* Contact Details */}
                <div className="contact-details">
                    <p><b>Address:</b> Sarjapur Road, construction, Bengaluru, India</p>
                    <p><b>Phone:</b> (123) 456-7890</p>
                    <p><b>Email:</b> customer-support@innodhee.com</p>
                </div>

                {/* Contact Form */}

                <div className="contact-form">
                    <form ref={form} onSubmit={sendEmail}>
                        <div className="input-field">
                            <input type="text"
                                value={formData.name}  // Controlled input
                                onChange={handleChange}
                                name="name"
                                placeholder="Your Name"
                                required />
                        </div>
                        <div className="input-field">
                            <input type="email"
                                value={formData.email}  // Controlled input
                                onChange={handleChange}
                                name="email"
                                placeholder="Your Email"
                                required />
                        </div>
                        <div className="input-field">
                            <textarea
                                name="mailmessage"
                                value={formData.mailmessage}  // Controlled input
                                onChange={handleChange}
                                placeholder="Your Message"
                                required></textarea>
                        </div>
                        <button type="submit" className="send-btn">Send</button>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default ContactUs;
